
'use strict';

var addToCart = require('base/product/base').addToCart;
const Search = require('core/search/search');

Search.initHistory = function () {
    let previousHistoryState;
    let doNotPushHistory = false;

    /**
     * Listen to all possible AJAX calls on a search page (Filters, sorting, show more, ...).
     */
    $(document).ajaxSuccess(
        function (event, xhr) {
            // Make the assumption that the existence of data attribute means  being present in the response means a grid-refresh
            if (xhr.responseText.indexOf('data-titlereturn="true"') >= 0) {
                if (!doNotPushHistory) {
                    setTimeout(function () {
                        history.pushState({ reapplyFilters: true }, document.title, decodeURI($('.permalink').val()));
                    });
                }

                doNotPushHistory = false;
            }
        }
    );

    /**
     * Listen to the back and forward button of the browser to detect URL changes.
     */
    window.addEventListener('popstate', () => {
        if ((history.state && history.state.reapplyFilters)
            || (previousHistoryState && previousHistoryState.reapplyFilters)) {
            const $resetButton = $('.refinement-bar button.reset');

            previousHistoryState = history.state;

            // This async call should not cause a new history state to be pushed.
            doNotPushHistory = true;

            // Use the reset button listeners to do a refresh
            $resetButton.data('href', decodeURI(window.location.href));
            $resetButton.trigger('click');
        }
    });
};

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
 function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
 function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
 function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

function shipToTogglePlacement() {
    var $shipToToggle = $('.shipto-toggle');

    // reset between desktop & mobile
    if (window.matchMedia('(min-width: 62em)').matches) {
        $shipToToggle.appendTo('.stt-desktop-wrap');
    } else {
        $shipToToggle.appendTo('.stt-mobile-wrap');
    }
}

function syncTiles() {
	var $productTile = $('.product-grid .product');

	if ($productTile.length === 0) { return; }

	var tilesPerRow = 2,
		totalTiles = $productTile.length,
		totalRows = Math.ceil(totalTiles / tilesPerRow),
		tileCount = 1,
		rowCount = 1,
		rowNum = 1;

	// Redefine variable for desktop
    if(window.matchMedia("(min-width: 544px)").matches) {
        tilesPerRow = 3;
	}

	// Add same class to all tiles in a row
    $productTile.each(function(index){

		// Add row data attribute to each tile per row
		$(this).attr('data-row', 'row' + rowNum);

        if(tileCount % tilesPerRow == 0){ // check if a new row is starting
            rowNum++; // increase row number
        }
		tileCount++; // increase tile count
	});

	while(rowCount <= totalRows){
        $('.product-grid .product[data-row="row' + rowCount + '"]').find('.tile-tag').matchHeight();
		$('.product-grid .product[data-row="row' + rowCount + '"]').find('.tile-body').matchHeight();
        $('.product-grid .product[data-row="row' + rowCount + '"]').find('.tile-buttons').matchHeight();
        $('.product-grid .product[data-row="row' + rowCount + '"]').matchHeight();
		rowCount++;
	}

}

function filterClearAll() {
    // Handle refinement value selection and reset click
    $('.filter-bar button.reset').on( 'click', function (e) {
        e.preventDefault();

        $.spinner().start();
        $(this).trigger('search:filter', e);
        $.ajax({
            url: $(this).data('href'),
            data: {
                page: $('.grid-footer').data('page-number'),
                selectedUrl: $(this).data('href')
            },
            method: 'GET',
            success: function (response) {
                parseResults(response);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

Search.changePage = function () {
    var $header = $('.page > header');
    var topGrid = $('.search-results').offset().top - $header.height();

    $('.container').on('click', '.pagination .page-numbers:not(.current)', function (e) {
        e.stopPropagation();
        var paginationUrl = $(this).data('url');
        e.preventDefault();

        $.spinner().start();
        $(this).trigger('search:changePage', e);
        $.ajax({
            url: paginationUrl,
            data: { selectedUrl: paginationUrl },
            method: 'GET',
            success: function (response) {
                $('.product-grid').empty().html(response);
                // Animate to top of grid
                $('html, body').animate({
                    scrollTop: topGrid
                }, 500);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

Search.init = function () {
    shipToTogglePlacement();
    syncTiles();

    /**
     * Listen to all possible AJAX calls on a search page (Filters, sorting, show more, ...).
     */
     $(document).ajaxSuccess(
        function (event, xhr) {
            // Make the assumption that the existence of data attribute means  being present in the response means a grid-refresh
            if (xhr.responseText.indexOf('data-titlereturn="true"') >= 0) {
                syncTiles();
            }
        }
    );

    $(window).smartresize(function () {
        shipToTogglePlacement();
        syncTiles();
    });

    $(document).on('click','.isSamePageGrid a',function() {
        $('html, body').animate({
            scrollTop: $(".container.search-results").offset().top - 150
        }, 400);
        return false;
    });

    if (localStorage.getItem("isNavProductGrid") !== null) {
        $('html, body').animate({
            scrollTop: $(".container.search-results").offset().top - 150
        }, 400);
        localStorage.removeItem("isNavProductGrid");
    }
};

Search.filterClearAll = function () {
    filterClearAll();

    /**
     * Listen to all possible AJAX calls on a search page (Filters, sorting, show more, ...).
     */
     $(document).ajaxSuccess(
        function (event, xhr) {
            // Make the assumption that the existence of data attribute means  being present in the response means a grid-refresh
            if (xhr.responseText.indexOf('data-titlereturn="true"') >= 0) {
                filterClearAll();
            }
        }
    );
};

Search.filter = function () {
    // Display refinements bar when Menu icon clicked
    $('.container').on('click', 'button.filter-results', function (e) {
        $('.refinement-bar, .modal-background').show().addClass('active');
        $('.refinement-bar').siblings().attr('aria-hidden', true);
        $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', true);
        $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', true);
        $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', true);
        $('.refinement-bar .close').focus();
    });
};

Search.closeRefinements = function () {
    // Refinements close button
    $('.container').on('click', '.refinement-bar button.close, .modal-background', function () {
        $('.refinement-bar, .modal-background').hide().removeClass('active');
        $('.refinement-bar').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
        $('.btn.filter-results').focus();
    });
};

Search.resize = function () {
    // Close refinement bar and hide modal background if user resizes browser
    $(window).resize(function () {
        $('.refinement-bar, .modal-background').hide().removeClass('active');
        $('.refinement-bar').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
    });
};

module.exports = Search;